/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import * as Sentry from '@sentry/remix';

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});

interface CustomWindow extends Window {
  ENV?: {
    SENTRY_ENVIRONMENT?: 'production' | 'staging';
  };
}

if (import.meta.env.PROD) {
  const integrations = [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    // Replay is only available in the client
    Sentry.replayIntegration(),
  ];

  const sentryEnvironment = (window as CustomWindow)?.ENV?.SENTRY_ENVIRONMENT;
  const sampleRate = sentryEnvironment != 'staging' ? 1.0 : 0.0;

  Sentry.init({
    dsn: 'https://8ba18c72055e570d6d6cba6947a83972@o4506094289944576.ingest.us.sentry.io/4507671796908032',
    integrations,
    tracesSampleRate: sampleRate,
    replaysSessionSampleRate: sampleRate,
    replaysOnErrorSampleRate: sampleRate,
  });
}
